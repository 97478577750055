.filter-query {
  position: relative;

  margin: 1rem;
  .input-query {
    color: var(--secondary);
    background-color: var(--background);
    border: none;
    width: 100%;
    border-radius: 0.5rem;
    height: 3rem;
    font-size: 1.2rem;
    padding-left: 1rem;
  }
  .input-icon {
    fill: var(--secondary);
    position: absolute;
    right: 1rem;
    top: 0.75rem;
    width: 1rem;
    &.close-icon {
      height: 1.5rem;
    }
  }
  &.LIGHT {
    .input-query {
      color: var(--background);
      background-color: var(--secondary);
    }
    .input-icon {
      fill: var(--background);
    }
  }
}
