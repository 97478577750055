.Card {
  transition: all 0.2s ease-in-out;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    transform: scale(1.03);
  }
  background-color: var(--secondary);
  margin-bottom: 2rem;

  .Card-header {
    align-items: center;
  }
  .Card-information {
    position: relative;

    padding: 1rem;
    padding-top: 0;
    .Card-header-title {
      width: 70%;
      margin-bottom: 0.5rem;
      color: var(--background);
    }
    .Card-header--icones {
      float: left;
    }
    .Card-header--icones,
    .Card-information-category-icon {
      svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
      }
    }
    .Card-information-category {
      color: var(--category-title);
      .Card-information-category-icon {
        svg {
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
  .Card-description {
    color: var(--secondary-2);
    padding: 1rem;
    padding-top: 0;
  }
  .Card-information-url-icon {
    width: 5rem;
    height: 5rem;

    display: block;
    position: absolute;
    top: -3rem;
    right: 1rem;
    border-radius: 4rem;
    background-color: var(--content);
    img {
      width: 4.5rem;
      height: 4.5rem;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      margin-left: 0.25rem;
      margin-top: 0.25rem;
      clip-path: circle(50% at 50% 50%);
    }
  }
}
.dark {
  .Card-information {
    .Card-information-category {
      color: var(--secondary-2);
    }
  }
}
.light {
  .Card {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--content);
    .Card-header-title {
      color: var(--secondary);
    }
  }
}
